import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './services/global.service';
import { Router } from '@angular/router';
import { WebsocketService } from './services/websocket.service';
import { RealTimeService } from './services/real-time.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'jelmi-web';
  public isLoading: boolean;
  public viewError: boolean;
  public dataIpService: any;
  public continue: boolean;

  constructor(
    private router: Router,
    private _translateService: TranslateService,
    public _webSocket: WebsocketService,
    private _realTimeService: RealTimeService,
    private _globalService: GlobalService) {
    // Add languages
    this._translateService.addLangs(['es', 'en']);
    this.continue = false;
  }

  ngOnInit(){
   
  }

}
