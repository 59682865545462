import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable, BehaviorSubject, from } from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private _url: string;
  private _secret_key: string;
  public data: any

  // Country data


   constructor(private _http: HttpClient) {
    this._url = environment.url;
    this._secret_key = environment.secret_key;
  }

  // Country data

  getCountryData(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });
    return this._http.get(`${this._url}/get-ip`, { headers: headers });
  }
  
  checkCountry(country: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });
    return this._http.get(`${this._url}/check-country/${country}`, { headers: headers });
  }

  checkStatus(id: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.get(`${this._url}search-payment/${id}`, { headers: headers });
  }

  registerPayment(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.post(`${this._url}register-payment/`, params, { headers: headers });
  }
  
}
