import { Injectable } from '@angular/core';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class RealTimeService {

  constructor(private _webSocket: WebsocketService) { }

  //Pagos
  sendPay(idUser: any, payment_id: any, status: string, merchant_order_id: any, payment_type: string, detail: string) {
    const payload: any = {
      idUser: idUser,
      payment_id: payment_id,
      status: status,
      merchant_order_id: merchant_order_id,
      payment_type: payment_type,
      detail: detail
    };
    this._webSocket.sendEvent('send-pay', payload)
  }

  receivePay() {
    return this._webSocket.receiveEvent('new-pay');
  }

}
