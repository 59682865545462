import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CommonModule } from '@angular/common';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'country-not-enabled',
    loadChildren: () => import('./errors/country-not-enabled/country-not-enabled.module').then(m => m.CountryNotEnabledModule)
  },
  {
    path: 'feedback/:id',
    loadChildren: () => import('../app/feedback/feedback.module').then(m => m.FeedbackModule)
  },
  {
    path: 'connection-error',
    loadChildren: () => import('./errors/connection/connection.module').then(m => m.ConnectionModule)
  },
  {
    path: '**',
    loadChildren: () => import('./errors/not-found/not-found.module').then(m => m.NotFoundModule)
  }
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
